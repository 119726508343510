@import "global";

.contact {
  @include padding(130px null);

  &__content {
    margin: auto;
  }
  &__column {
    &-wrapper {
      @include flex-grid-column(10,false);
    }
    &-perex {
      @include flex-grid-column(12/5*2,false);
    }
    &-item {
      @include flex-grid-column(12/5,false)
    }
  }
  &__perex {
    width: 315px;
    max-width: 100%;
    color: $cGray-400;
  }

  &__service {
    color: $cPrimary;
    font-size: rem-calc(13);
  }

  &__title {
    padding-bottom: 30px;
    max-width: 500px;
  }
  &__btn {
    margin-top: 40px;
  }

  &__link {
    margin-top: 10px;
    display: block;
    text-decoration: none;
    color: $cGray-400;
    font-size: rem-calc(13);
    line-height: 1.3;
    transition: color .3s;

    &:hover {
      color: $cPrimary;
    }
  }

  &__all-contacts {
    @include flex-grid-column(10,false);
  }

  @include breakpoint(tablet-portrait down) {
    &__perex {
      width: 100%;
    }
    &__column-perex {
      @include flex-grid-column(12);
      margin-bottom: 30px;
    }
    &__column-item {
      @include flex-grid-column(4);
    }
    &__column-wrapper {
      @include flex-grid-column(12);
    }
  }
  @include breakpoint(phone down) {
    @include padding(65px null);
    &__column-item {
      @include flex-grid-column(12);
      margin-bottom: 20px;
    }
  }
}
